<template>
  <div>

    <div class="deta">

      <p class="title">基本信息</p>
      <el-row>
        <el-col :span="6">
          <p class="info">姓名：{{ deta.name }}</p>
        </el-col>
        <el-col :span="6">
          <p class="info">性别：{{ deta.gender == 0 ? '女' : '男' }}</p>
        </el-col>
        <el-col :span="6">
          <p class="info">年龄：{{ deta.age }}</p>
        </el-col>
        <el-col :span="6">
          <p class="info">检查/复诊日期：{{ deta.appointmentDate }}</p>
        </el-col>
      </el-row>

      <p class="title" v-if="deta.reportType == 5" >患者自传医疗数据</p>

      <ul class="patient" v-if="deta.reportType == 5" >
        <li>
          <div class="content">
            <span>症状描述</span>
            <p>{{ deta.shows }}</p>
          </div>
          <div class="content">
            <span>具体描述</span>
            <p>{{ deta.description }}</p>
          </div>
        </li>
      </ul>

<!--      <p v-if="deta.pathPDF != null" class="title">报告明细-{{ deta.reportTypeName }}</p>-->

<!--      <ul class="pdf" v-if="deta.pathPDF != null" >-->
<!--        <li @click="link_pdf(deta.pathPDF)">-->
<!--          <img v-if="deta.reportType == 1" src="../../assets/DoctorDeta/commodity1.png" alt="">-->
<!--          <img v-if="deta.reportType == 5" src="../../assets/DoctorDeta/commodity2.png" alt="">-->
<!--          <img v-if="deta.reportType == 0" src="../../assets/DoctorDeta/commodity3.png" alt="">-->
<!--          <img v-if="deta.reportType == 2" src="../../assets/DoctorDeta/commodity4.png" alt="">-->
<!--          <img v-if="deta.reportType == 3" src="../../assets/DoctorDeta/commodity5.png" alt="">-->
<!--          <p>{{ deta.path }}</p>-->
<!--        </li>-->
<!--      </ul>-->

      <p v-if="deta.imgs != null && deta.imgs.length != 0" class="title">报告明细-{{ deta.reportTypeName }}</p>

      <ul class="pdf" v-if="deta.imgs != null && deta.imgs.length != 0" >
        <li v-for="(item,index) in deta.imgs" :key="index" >
          <el-image
              style="width: 50%; height: 100%; max-height: 300px; "
              @click="imgCLick(index)"
              :src="item">
          </el-image>
        </li>
      </ul>
      <el-image-viewer
          v-if="showViewerDemo"
          :on-close="closeViewerDemo"
          :url-list="img_list" />


      <p class="title">随访建议</p>

      <el-table
          :data="table"
          style="width: 100%;" >
        <el-table-column
            prop="deptCode"
            align="center"
            label="科室">
          <template slot-scope="scope">
            <span>{{ $store.state.dept.filter((item) => { return item.dictKey == scope.row.deptCode  })[0].dictValue }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="reason"
            align="center"
            show-overflow-tooltip
            label="原因">
        </el-table-column>
        <el-table-column
            prop="diagnosis"
            align="center"
            show-overflow-tooltip
            label="诊断结论">
        </el-table-column>
        <el-table-column
            prop="furtherConsultation"
            align="center"
            label="复查时间">
          <template slot-scope="scope">
            <span>{{ scope.row.consultationNum + $store.state.unit.filter((item) => { return item.dictKey == scope.row.consultationUnit  })[0].dictValue }}</span>
          </template>
        </el-table-column>
        <el-table-column
            v-if="deta.diagnosisStatus != 2"
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
              <el-button v-if="!deta.diagnosisStatus != 2" type="text" size="small" @click="exit(scope.row,scope.$index)" >编辑</el-button>
              <el-button v-if="!deta.diagnosisStatus != 2" type="text" size="small" @click="del(scope.row,scope.$index)" >删除</el-button>
          </template>
        </el-table-column>
        <template slot="empty" v-if="deta.noDiagnosis == 1" >
          <span>无建议</span>
        </template>
      </el-table>

      <el-form
          v-if="deta.diagnosisStatus != 2"
          :inline="true"
          :rules="rules"
          ref="form"
          label-width="80px"
          :hide-required-asterisk="true"
          :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="原因" prop="reason" >
              <el-input type="textarea" v-model="form.reason" clearable placeholder="请输入套餐介绍"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="诊断结论" prop="diagnosis" >
              <el-input type="textarea" v-model="form.diagnosis" clearable placeholder="请输入套餐介绍"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <div class="doctors">
              <el-form-item label="复查时间" prop="consultationNum" class="consultationNum" >
                <el-input maxlength="3" v-model="form.consultationNum" clearable placeholder="请输入复查时间" ></el-input>
              </el-form-item>
              <el-form-item prop="consultationUnit">
                <el-select v-model="form.consultationUnit" clearable placeholder="请选择复查时间">
                  <el-option v-for="item in $store.state.unit" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey" ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button @click="add(form)">{{ form_button }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="footer_button">
        <el-button size="small" @click="cancel" >取消</el-button>
        <el-button size="small" v-if="deta.diagnosisStatus != 2" @click="no_proposal" type="primary" >不适随诊</el-button>
        <el-button size="small" v-if="deta.diagnosisStatus != 2" :loading="loads" @click="onSubmit(table)" type="primary" >提交</el-button>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "DoctorDeta",
  data() {
    return{
      form: {
        reportId: null, // 报告id
        id: null, // 建议id
        appointmentDate: null, // 检查/复诊日期
        reason: null, // 原因
        diagnosis: null, // 诊断结论
        consultationNum: null, // 医嘱numbre
        consultationUnit: null, // 医嘱日期
        dept: null, // 科室
      },
      rules: {
        reason: [
            { required: true, message: '请输入原因', trigger: 'change' }
        ],
        diagnosis: [
            { required: true, message: '请输入诊断结论', trigger: 'change' }
        ],
        consultationNum: [
            { required: true, message: '请输入复查时间', trigger: 'change' },
            {pattern: /^[1-9][0-9]*$/, message: '复诊时间只能输入整数'}
        ],
        consultationUnit: [
            { required: true, message: '请选择复查时间', trigger: 'change' }
        ],
      },
      form_button: "添加",
      table: [],
      del_table: [],
      deta: {},
      index: null,
      loads: false,
      // 图片查看器
      img_list: [],
      showViewerDemo: false,
    }
  },
  created() {
    this.get_list(this.$route.query.id);
    console.log(this.$store.state.unit);
  },
  mounted() {

  },
  methods: {
    imgCLick(i) {

      this.img_list= [];

      let leng = this.deta.imgs.length;
      this.deta.imgs.forEach((item,index) => {
        if(index - i >= 0) {
          this.img_list[index - i] = item;
        }else {
          this.img_list[index - i + leng] = item;
        }
      })
      this.showViewerDemo = true;
    },
    closeViewerDemo() {
      this.showViewerDemo = false;
    },
    // 取消
    cancel() {
      this.$router.push({
        path: "/Doctor",
        query: {
          activeName: this.$route.query.state
        }
      })
    },
    // 无建议
    no_proposal() {

      let obj= {}
      obj["reportId"]= this.$route.query.id;
      if(this.table.length != 0) {

        this.$confirm("您已填写随访建议，是否确认选择无建议？")
            .then(() => {
              this.$post("diagnosis/noDiagnosis",obj)
                  .then(res => {
                    if(res) {
                      this.$router.push({
                        path: "/Doctor",
                        query: {
                          activeName: this.$route.query.state
                        }
                      })
                    }
                  })
            })
            .catch(() => {

            });

      }else {
        this.$post("diagnosis/noDiagnosis",obj)
            .then(res => {
              if(res) {
                this.$router.push({
                  path: "/Doctor",
                  query: {
                    activeName: this.$route.query.state
                  }
                })
              }
            })
      }

    },
    // 提交
    onSubmit(e) {
      console.log(e)

      this.loads= true;

      let obj= {};
      obj["createDiagnosisAdviceList"]= e;
      obj["deleteDiagnosisAdviceDTO"]= this.del_table;

      this.$post("diagnosis/createDiagnosisAdvice",obj)
        .then(res => {
          this.loads= false;
          if(res) {
            console.log(res);
            this.$router.push({
              path: "/Doctor",
              query: {
                activeName: this.$route.query.state
              }
            })

          }
        })

    },
    // 编辑
    exit(e,i) {
      console.log(e,i)
      this.index= i;

      this.form_button= "修改";

      this.form.dept= e.deptCode;
      this.form.reason= e.reason;
      this.form.diagnosis= e.diagnosis;
      this.form.consultationNum= e.consultationNum;
      this.form.consultationUnit= e.consultationUnit.toString();
      this.form.id= e.reportAdviceId;


    },
    // 删除
    del(e,i) {
      console.log(e,i)

      this.$confirm("确认删除该随访建议？")
          .then(() => {

            if(e.reportAdviceId) {
              let obj= {};
              obj["reportId"]= this.$route.query.id;
              obj["reportAdviceId"]= e.reportAdviceId;

              // this.$post("diagnosis/delete",obj)
              //   .then(res => {
              //     if(res) {
              //       console.log(res);
              //       this.table.splice(i,1);
              //     }
              //   })
              this.del_table.push(obj);
            }

            this.table.splice(i,1);

          })
          .catch(() => {

          });

    },
    // 添加/编辑
    add(formName) {
      this.$refs.form.validate((valid) => {
        if(valid) {
          console.log(formName);


          if(this.index == null) {
            let obj= {};
            obj["id"]= null;
            obj["deptCode"]= formName.dept;
            obj["reason"]= formName.reason;
            obj["diagnosis"]= formName.diagnosis;
            obj["consultationNum"]= formName.consultationNum;
            obj["consultationUnit"]= formName.consultationUnit;
            obj["reportId"]= this.$route.query.id;
            obj["appointmentDate"]= formName.appointmentDate;

            this.table.push(obj);
          }else {
            this.table[this.index].reason= formName.reason;
            this.table[this.index].diagnosis= formName.diagnosis;
            this.table[this.index].consultationNum= formName.consultationNum;
            this.table[this.index].consultationUnit= formName.consultationUnit;
          }
          this.index= null;
          this.form_button= "添加";

          this.$refs.form.resetFields();

        }
      })

    },
    // 获取详情
    get_list(y) {
      this.$get("diagnosis/detail",y,true)
        .then(res => {
          if(res) {
            console.log(res);


            res.data.reportAdvice.forEach((item) => {
              item["reportId"]= this.$route.query.id;
              item["appointmentDate"]= res.data.appointmentDate;
            })

            this.deta= res.data;
            this.table= res.data.reportAdvice;
            this.form.reportId= res.data.id;
            this.form.dept= res.data.dept;
            this.form.appointmentDate= res.data.appointmentDate;
          }
        })
    },
    link_pdf(e) {
      window.open(e, '_blank','toolbar=yes, width=900, height=700');
    }
  },
}
</script>

<style lang="less" scoped>
  @import "../../style/Doctor/DoctorDeta";
</style>
