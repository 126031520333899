import { render, staticRenderFns } from "./DoctorDeta.vue?vue&type=template&id=f00f912a&scoped=true&"
import script from "./DoctorDeta.vue?vue&type=script&lang=js&"
export * from "./DoctorDeta.vue?vue&type=script&lang=js&"
import style0 from "./DoctorDeta.vue?vue&type=style&index=0&id=f00f912a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f00f912a",
  null
  
)

export default component.exports